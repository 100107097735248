import {LiFiWidget, WidgetConfig} from '@lifi/widget';
import './App.css';

function App() {

    const widgetConfig: WidgetConfig = {
        integrator: 'cra-example',
        containerStyle: {
            border: '1px solid rgb(234, 234, 234)',
            borderRadius: '16px',
        },
        variant: 'expandable',
    };

    return (
        <LiFiWidget
            config={widgetConfig} integrator={'cra-example'}
        />
    );
}

export default App;
